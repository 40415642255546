
import Checkbox from '@/components/Checkbox.vue'
import { reactive, defineComponent, ref, computed } from 'vue'
import useSnackbar from '@/composables/useSnackbar'
import PageHeader from '@/components/PageHeader.vue'
import { getBuyerDetails, updateBuyerDetails } from '@/expressway-api/auth.api'
import { UserDetail } from '@/models/User'
import Input from '@/components/Input.vue'
import useVuelidate from '@vuelidate/core'
import Spinner from '@/components/Spinner.vue'
import { required, email, helpers } from '@vuelidate/validators'
import ModalLink from '@/components/CMSModalLink.vue'
import InfoIcon from '@/components/vectors/Info.vue'
import { AxiosError } from 'axios'
import RevealableInput from '@/components/RevealableInput.vue'

export default defineComponent({
  components: {
    'v-checkbox': Checkbox,
    'v-input': Input,
    ModalLink,
    InfoIcon,
    RevealableInput,
    Spinner,
    PageHeader
  },
  // eslint-disable-next-line max-lines-per-function
  setup () {
    const buyerDetails = ref({} as UserDetail)
    const editingCustomer = ref(false)
    const loading = ref(false)
    const { setSnackbar } = useSnackbar()
    const state = reactive({
      email: '',
      firstName: '',
      lastName: '',
      isSendingInfoAllowed: false,
      password: ''
    })
    const emailChanged = computed(() => state.email !== buyerDetails.value.Email)
    const rules = {
      email: {
        required,
        email: helpers.withMessage('Please enter a valid Email Address', email)
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      isSendingInfoAllowed: {},
      password: {
        requiredIf: helpers.withMessage('Please enter your password', value =>
          emailChanged.value ? !!value : true)
      }
    }
    const validation = useVuelidate(rules, state)
    getBuyerDetails().then((r: UserDetail) => {
      buyerDetails.value = { ...r }
      state.email = r.Email
      state.firstName = r.FirstName
      state.lastName = r.LastName
      state.isSendingInfoAllowed = r.IsSendingInfoAllowed
    }).catch((e: AxiosError) => {
      setSnackbar(e.message)
    })
    const updatePersonalDetails = async () => {
      if (await validation.value.$validate()) {
        loading.value = true
        const UpdatedInformation: UserDetail = { ...buyerDetails.value, Password: '' }
        UpdatedInformation.Email = state.email
        UpdatedInformation.FirstName = state.firstName
        UpdatedInformation.LastName = state.lastName
        UpdatedInformation.IsSendingInfoAllowed = state.isSendingInfoAllowed
        if (emailChanged.value) {
          UpdatedInformation.Password = state.password
        }
        updateBuyerDetails(UpdatedInformation).then(() => {
          buyerDetails.value = { ...UpdatedInformation }
          editingCustomer.value = false
          loading.value = false
          setSnackbar('Your details have been updated.', 'success')
        }).catch((e: AxiosError) => {
          setSnackbar(e.message)
          loading.value = false
        })
      }
    }
    const cancelEdition = () => {
      state.email = buyerDetails.value.Email
      state.firstName = buyerDetails.value.FirstName
      state.lastName = buyerDetails.value.LastName
      state.isSendingInfoAllowed = buyerDetails.value.IsSendingInfoAllowed
      editingCustomer.value = false
    }
    return {
      emailChanged,
      editingCustomer,
      Checkbox,
      buyerDetails,
      v$: validation,
      updatePersonalDetails,
      cancelEdition,
      loading
    }
  }
})
